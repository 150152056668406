$(document).bind('_js_ready', function() {
    $('#bandeau_page .slider_bandeau').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        fade: true,
        responsive:[
            {
                breakpoint: 1023,
                settings: {
                    autoplay: false,
                }
            }
        ]
    });
});
